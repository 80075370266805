const item = {
    item1: require('../images/items/item-01.jpg'),
    item2: require('../images/items/item-02.jpg'),
    item3: require('../images/items/item-03.jpg'),
    item4: require('../images/items/item-04.jpg'),
    item5: require('../images/items/item-05.jpg'),
    item6: require('../images/items/item-06.jpg'),
    item7: require('../images/items/item-07.jpg'),
    item8: require('../images/items/item-08.jpg'),
    item9: require('../images/items/item-09.jpg'),
    item10: require('../images/items/item-10.jpg'),
    item11: require('../images/items/item-11.jpg'),
    item12: require('../images/items/item-12.jpg'),
    item13: require('../images/items/item-13.jpg'),
    item14: require('../images/items/item-14.jpg'),
    item15: require('../images/items/item-15.jpg'),
    item16: require('../images/items/item-16.jpg'),
    item17: require('../images/items/item-17.jpg'),
    item18: require('../images/items/item-18.jpg'),
    item19: require('../images/items/item-19.jpg'),
    item20: require('../images/items/item-20.png'),
    item21: require('../images/items/item-21.png'),
    item22: require('../images/items/item-22.png'),
    item23: require('../images/items/item-23.png'),
    item24: require('../images/items/item-24.png'),
    item25: require('../images/items/item-25.png'),
    item26: require('../images/items/item-26.png'),    
    item27: require('../images/items/item-27.png'),
    item28: require('../images/items/item-28.png'),
    item29: require('../images/items/item-29.png'),
    item30: require('../images/items/item-30.png'),
    item31: require('../images/items/item-31.png'),
    item32: require('../images/items/item-32.png'),
    item33: require('../images/items/item-33.png'),
    item34: require('../images/items/item-34.png'),
    item35: require('../images/items/item-35.png'),
    item36: require('../images/items/item-36.png'),
    item37: require('../images/items/item-37.png'),
    item38: require('../images/items/item-38.png'),
    item39: require('../images/items/item-39.png'),
    item40: require('../images/items/item-40.png'),
    item41: require('../images/items/item-41.png'),
    item42: require('../images/items/item-42.png'),
    item43: require('../images/items/item-43.png'),
    item44: require('../images/items/item-44.png'),
    item45: require('../images/items/item-45.png'),
    item46: require('../images/items/item-46.png'),
    item47: require('../images/items/item-47.png'),
    item48: require('../images/items/item-48.png'),
    item49: require('../images/items/item-49.png'),
    item50: require('../images/items/item-50.png'),
    item51: require('../images/items/item-51.png'),
    item52: require('../images/items/item-52.png'),
    item53: require('../images/items/item-53.png'),
    item54: require('../images/items/item-54.png'),
    item55: require('../images/items/item-55.png'),
    item56: require('../images/items/item-56.png'),
    storybg1: require('../images/items/story-bg-01.jpg'),
    story1: require('../images/items/story-01.png'),
    storybg2: require('../images/items/story-bg-02.jpg'),
    story2: require('../images/items/story-02.png'),
    storybg3: require('../images/items/story-bg-03.jpg'),
    story3: require('../images/items/story-03.png'),
    storybg4: require('../images/items/story-bg-04.jpg'),
    story4: require('../images/items/story-04.png'),
    storybg5: require('../images/items/story-bg-05.png'),
    story5: require('../images/items/story-05.png'),
    story6: require('../images/items/story-06.png'),
    storybg6: require('../images/items/story-bg-06.png'),
    profileMayanCoins: require('../images/profiles/mayan-coins.jpg'),
    profile5CrownFire: require('../images/profiles/5-crown-fire.png'),
    profileLostBook: require('../images/profiles/lost-book.jpg'),
    profile40WildClover: require('../images/profiles/40-wild-clover.jpg'),
    profileBookOfDread: require('../images/profiles/book-of-dread.png'),
}

export default item;