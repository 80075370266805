import item from './item'

const dataMontono2 = [
    {
        img: item.item31,
        id: 'fear-of-dark',
    },
    {
        img: item.item48,
        id: 'caps-and-crowns',
    },
    {
        img: item.item11,
        id: '5-clover-fire',
    },
    {
        img: item.item1,
        id: 'mayan-coins',
    },
    {
        img: item.item42,
        id: 'chilli-double',
    },
    {
        img: item.item43,
        id: 'hot-rush-crown-burst',
    },
    {
        img: item.item45,
        id: 'slot-royale',
    },
    {
        img: item.item35,
        id: '40-fruit-frenzy',
    },
]

export default dataMontono2;