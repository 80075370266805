import item from './item';

const dataPortfolio = [
    {
        step: 'Lock&Cash',
        title: 'Mayan Coins',
        desc: 'Stunning visuals and huge prizes. Get all 15 coins in Lock&Cash bonus and score the massive GRAND prize!',
        profile: item.profileMayanCoins,
        id: 'mayan-coins'
    },
    {
        step: 'Expanding Wild',
        title: '5 Crown Fire',
        desc: 'A future classic with a bang. This fruit slot features Expanding Wild mechanic for triple the fun.',
        profile: item.profile5CrownFire,
        id: '5-crown-fire'
    },
    {
        step: 'Book Mechanics',
        title: 'Book Of Dread',
        desc: 'Explore a world of horror and riches beyond imagination. Book mechanics with stunning modern visuals.',
        profile: item.profileBookOfDread,
        id: 'book-of-dread'
    },
    {
        step: 'Classic Fruits',
        title: '40 Wild Clover',
        desc: 'Dynamic and fast, with free spins feature for even bigger wins! This is your lucky clover.',
        profile: item.profile40WildClover,
        id: '40-wild-clover'
    },
]

export default dataPortfolio;