const dataCareer = [
    {
        img: require('../images/career/2d-animator.png'),
        id: '2d-animator',
    },
    // {
    //     img: require('../images/career/programmer.png'),
    //     id: 'programmer',
    // },
    {
        img: require('../images/career/account-manager.png'),
        id: 'account-manager',
    },
    // {
    //     img: require('../images/career/designer-illustrator.png'),
    //     id: 'designer-illustrator',
    // },
     {
        img: require('../images/career/mathematician.png'),
        id: 'mathematician',
    },
    // {
    //     img: require('../images/career/illustrator.png'),
    //     id: 'illustrator',
    // },
];

export default dataCareer;